import React from 'react';
import { motion } from 'framer-motion';
import { ByMyCarIcon } from '@spa-cars/ui';
import { Layout } from '../components';

const sectionVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: [0, 1],
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
};

function Page404() {
  return (
    <Layout title="Lista de categorías" routesHistory={[]}>
      <motion.section
        variants={sectionVariant}
        animate="animate"
        initial="initial"
        className="flex items-center justify-center flex-col  overflow-hidden"
      >
        <h3 className="flex items-center justify-center flex-col sm:flex-row mt-20 md:mt-36">
          <span className="text-primary-300 font-semibold tracking-wide text-xl">
            No hemos encontrado lo que buscas...
          </span>{' '}
        </h3>
        <motion.div
          animate={{ x: [0, 5, 0], y: [0, 5, 0] }}
          transition={{ duration: 3, repeat: Infinity }}
          className=" flex w-full "
        >
          <ByMyCarIcon className="w-[20rem] sm:w-[27rem] m-auto" />
        </motion.div>
      </motion.section>
    </Layout>
  );
}

export default Page404;
